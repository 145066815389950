<template>
  <div class="csn-pnp-account-content">
    <div class="csn-pnp-account-button-group">
      <button
        class="btn casino-btn casino-btn-default pull-left"
        @click="handleLoginButtonClick"
      >
        {{ t('login') }}
      </button>
      <button
        class="btn casino-btn casino-btn-pink pull-left"
        @click="handleRegistrationButtonClick"
      >
        {{ t('registration') }}
      </button>
    </div>
  </div>
</template>
<script>
import { PNP_ACCOUNT_MODAL, RouteName } from '@/constants'
import { navigateTo } from '@/helpers'

export default {
  name: 'PnpAccountContent',
  computed: {
    t() {
      return this.$createComponentTranslator(PNP_ACCOUNT_MODAL)
    },
  },
  methods: {
    closeModal(fn) {
      return this.$emit('close', fn)
    },
    handleLoginButtonClick() {
      this.closeModal(() => navigateTo({ name: RouteName.LOGIN }))
    },
    handleRegistrationButtonClick() {
      this.closeModal(() => navigateTo({ name: RouteName.REGISTRATION }))
    },
  },
}
</script>
